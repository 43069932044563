<template>
  <div class="flex flex-col lg:flex-row justify-between space-y-5 lg:space-y-0 px-16 py-6 border-t-2 border-black bg-beige">
    <button
      class="font-Kontesa text-2xl leading-none font-semibold border-2 border-black rounded-full px-12 py-5 uppercase bg-beige-low"
    >
      Giving Circles
    </button>

    <div
      class="flex flex-col lg:flex-row items-center gap-2 lg:gap-10 relative font-semibold text-xl leading-none uppercase"
    >
      <div class="">
        <span class="font-Kontesa text-[32px] absolute ml-3 lg:ml-0"
          >©</span
        ><span class="font-Kontesa pl-8"> Dollar Donation Club 2024</span>
      </div>

      <a href="#" class="font-Kontesa border-b-2 border-black pb-[0.5px]">Terms & conditions</a>

      <a href="#" class="font-Kontesa border-b-2 border-black pb-[0.5px]">Privacy Policy</a>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
//
</style>